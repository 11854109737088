import { Route, Switch, Redirect } from 'react-router';
// import { useSelector } from 'react-redux';
import Loadable from 'react-loadable';
import React from 'react';

import { useIdentifyUser } from '@helpers/useIdentifyUser';
import useServerTimeOffset from '@helpers/useServerTimeOffset';
// import { isUserBeta } from '@selectors';
import Loader from './components/Loader';
import { logOut } from './actions/authentication';
import { isAuthenticated } from './helpers';

import Loading from './components/Loading';
// import PrivacyPolicy from './views/Agreements/PrivacyPolicy';

import store from '../shared/store';

const Reload = Loadable({
  loader: () => import('./views/Reload'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

const StartQuiz = Loadable({
  loader: () => import('./views/StartQuiz'),
  loading: Loading,
});

const EndQuiz = Loadable({
  loader: () => import('./views/EndQuiz'),
  loading: Loading,
});

const SampleQuiz = Loadable({
  loader: () => import('./views/SampleQuiz'),
  loading: Loading,
});

const ForgotPassword = Loadable({
  loader: () => import('./views/SignIn/components/ForgotPassword'),
  loading: Loading,
});

const NotFound = Loadable({
  loader: () => import('./views/NotFound'),
  loading: Loading,
});

const SideProject = Loadable({
  loader: () => import('./views/SideProject'),
  loading: Loading,
});

const ResetLinkSent = Loadable({
  loader: () => import('./views/SignIn/components/ResetLinkSent'),
  loading: Loading,
});

// const ResetPassword = Loadable({
//   loader: () => import('./views/SignIn/components/ResetPassword'),
//   loading: Loading,
// });

const Submission = Loadable({
  loader: () => import('./views/Submission'),
  loading: Loading,
});

// const Settings = Loadable({
//   loader: () => import('./views/Settings'),
//   loading: Loading,
// });

// const SignIn = Loadable({
//   loader: () => import('./views/SignIn'),
//   loading: Loading,
// });

// const SignUp = Loadable({
//   loader: () => import('./views/SignUp'),
//   loading: Loading,
// });

const Ticket = Loadable({
  loader: () => import('./views/Ticket'),
  loading: Loading,
});

const External = Loadable({
  loader: () => import('./views/External'),
  loading: Loading,
});

const Unsubscribe = Loadable({
  loader: () => import('./views/Unsubscribe'),
  loading: Loading,
});

// const TOS = Loadable({
//   loader: () => import('./views/Agreements/TOS'),
//   loading: Loading,
// });

// const CodeOfConduct = Loadable({
//   loader: () => import('./views/Agreements/CodeOfConduct'),
//   loading: Loading,
// });

// const RefundPolicy = Loadable({
//   loader: () => import('./views/Agreements/RefundPolicy'),
//   loading: Loading,
// });

// const SignUpGoogle = Loadable({
//   loader: () => import('./views/SignIn/components/GoogleSignUp'),
//   loading: Loading,
// });

const Form = Loadable({
  loader: () => import('./views/Form'),
  loading: Loading,
});

// const HackathonsPublic = Loadable({
//   loader: () => import('./views/HackathonsPublic'),
//   loading: Loading,
// });

const Idea = Loadable({
  loader: () => import('./views/Idea'),
  loading: Loading,
});

// const AccountDeletionFeedback = Loadable({
//   loader: () => import('./views/AccountDeletionFeedback'),
//   loading: Loading,
// });

/*
const SubmitIdea = Loadable({
  loader: () => import('./views/SubmitIdea'),
  loading: Loading,
});
*/
const RouteProtected = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthenticated()) {
        return <Component {...props} />;
      }

      // Log the user out: clear the object
      // and redirect to signin page.
      store.dispatch(logOut());

      return <Reload shouldRedirectToSignIn />;
    }}
  />
);

/**
 * This handler passes the control to the server when
 * coming to this page via client side routing and
 * the user is a beta user so that the server can
 * pass the control to the v2 Next.js app for that
 * route.
 */
// const RouteBeta = ({ component: Component, ...rest }) => {
//   const isBeta = useSelector(({ user }) => isUserBeta(user));

//   if (isBeta) {
//     return <Route {...rest} component={Reload} />;
//   }

//   return <Route {...rest} render={componentProps => <Component {...componentProps} />} />;
// };

/**
 * Fetch the user info if the user is authenticated
 * and render the Routes with user loading info
 */
const RoutesWithUserState = () => {
  const isUserLoading = useIdentifyUser();
  useServerTimeOffset();

  return <Routes isLoading={isUserLoading} />;
};

/**
 * Render all the CSR routes of the app
 * This component needs to be memoized to avoid unnecessary
 * re-rendering that might happen because of the useIdentifyUser
 * hook
 */
const Routes = React.memo(({ isLoading }) => {
  if (isLoading) {
    return <Loader />;
  }

  return (
    <Switch>
      <RouteProtected exact path="/" component={Dashboard} />
      <Route exact path="/home" component={Reload} />

      <Route exact path="/quiz/start" component={StartQuiz} />
      <RouteProtected exact path="/quiz/end" component={EndQuiz} />
      <RouteProtected exact path="/quiz/sample" component={SampleQuiz} />
      <RouteProtected exact path="/quiz" component={Reload} />

      <Route exact path="/reset-sent" component={ResetLinkSent} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={Reload} />

      <Route exact path="/signin/google" component={Reload} />
      <Route exact path="/signin" component={Reload} />

      <Route exact path="/signup" component={Reload} />
      <Route path="/signup/:flow" component={Reload} />
      <Route exact path="/signup/:hackathonSlug/:formType" component={Form} />

      <Route path="/:hackathonSlug/ticket" component={Ticket} />
      <Route exact path="/external-apply/:hackathonSlug" component={External} />
      <RouteProtected path="/:hackathonSlug/dashboard" component={Dashboard} />
      <RouteProtected exact path="/hackathons/applied" component={Reload} />
      <RouteProtected exact path={['/badges', '/badges/:status']} component={Dashboard} />
      <RouteProtected exact path="/projects" component={Reload} />

      <Route exact path="/hackathons/explore" render={() => <Redirect to="/hackathons" />} />
      <Route exact path="/hackathons/my" render={() => <Redirect to="/hackathons/applied" />} />
      <Route exact path="/skills" render={() => <Redirect to="/badges" />} />

      <RouteProtected path="/profile" component={Dashboard} />
      <RouteProtected exact path="/project/submit" component={Submission} />
      <RouteProtected
        exact
        path={['/project/new/:projectSlug', '/project/update/:projectSlug']}
        component={SideProject}
      />

      <Route exact path="/code-of-conduct" component={Reload} />

      {/** These paths are now handled by the Next.js app */}
      <Route exact path="/hackathons" component={Reload} />
      <Route exact path="/explore" component={Reload} />

      <Route exact path="/unsubscribe/:token" component={Unsubscribe} />
      <Route path="/settings" component={Reload} />
      <Route path="/settings/:slug" component={Reload} />

      <Route path="/careers" component={Reload} />
      <Route path="/organize" component={Reload} />

      <Route exact path="/submissions/:projectSlug" component={Reload} />
      <Route exact path="/projects/:projectSlug" component={Reload} />
      <Route exact path="/(@):username" component={Reload} />
      <Route exact path="/idea/submit" component={Reload} />
      <Route exact path="/ideas/:slug" component={Idea} />

      <Route exact path="/terms-of-use" component={Reload} />
      <Route exact path="/privacy-policy" component={Reload} />
      <Route exact path="/refund-policy" component={Reload} />

      <Route exact path="/feedback" component={Reload} />

      <Route path="/404" component={NotFound} />
      <Route component={NotFound} />
    </Switch>
  );
});

export default RoutesWithUserState;
